import * as React from 'react';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { firebase } from '../lib/firebase';

export default function useAuthListener() {
  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem('authUser')),
  );
  React.useEffect(() => {
    const auth = getAuth(firebase);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        localStorage.setItem('authUser', JSON.stringify(user));
        setUser(user);
      } else {
        localStorage.removeItem('authUser');
        // anonymous login
        const auth = getAuth(firebase);
        signInAnonymously(auth)
          .then(() => {
            localStorage.setItem('authUser', JSON.stringify(user));
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
            console.log(errorCode, errorMessage);
          });
        setUser(null);
      }
    });
  });
  return { user };
}
