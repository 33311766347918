import React from 'react';
import ReactDOM from 'react-dom';
import './styles/app.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import * as ROUTES from './constants/routes';

const Dashboard = React.lazy(() => import('./pages/dashboard'));
const Tutorial = React.lazy(() => import('./pages/tutorial'));
const NotFound = React.lazy(() => import('./pages/notFound'));
const Replay = React.lazy(() => import('./pages/replay'));
ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
        <Route path={ROUTES.TUTORIAL} element={<Tutorial />} />
        <Route path="*" element={<NotFound />} />
        <Route path="replay/:docId" element={<Replay />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
