import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCxMVfscrr6O4yJIx_mMsYZS2StUGo_-yM',
  authDomain: 'sfworld.firebaseapp.com',
  projectId: 'sfworld',
  storageBucket: 'sfworld.appspot.com',
  messagingSenderId: '752442473171',
  appId: '1:752442473171:web:ce9f4223d6af0cebe17226',
  measurementId: 'G-7MB704ZFME',
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const firestore = getFirestore(firebase);
const analytics = getAnalytics();

export { firebase, firestore, analytics };
