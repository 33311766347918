import * as React from 'react';
import { Outlet } from 'react-router-dom';
import useAuthListener from './hooks/useAuthListener';
import { UserContext } from './context/user.context';

const App = function () {
  const { user } = useAuthListener();
  return (
    <UserContext.Provider value={{ user }}>
      <React.Suspense fallback={<p>Loading...</p>}>
        <Outlet />
      </React.Suspense>
    </UserContext.Provider>
  );
};
export default App;
